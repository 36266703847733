<template>
	<div class="htlist"  @scroll.passive="getScroll($event)">
		<el-container>
			<el-main class="center-main" >
				<div class="filter">
					<h3>筛选</h3>
					<el-tabs class="tabs" v-model="activeName">
						<el-tab-pane label="价格/星级" name="0">
							<span slot="label"
								:class="{ spot:(htCnSonTags.start.length||htCnSonTags.input.length)>0?true:false }"></span>
							<span slot="label">价格/星级</span>
							<ht-start ref="start" @getInputObj="getInputObj" @changeList="changeList"
								@removeAll="removeAll"></ht-start>
						</el-tab-pane>
						<el-tab-pane>
							<span slot="label"
								:class="{ spot:(htCnSonTags.hotel.length||htCnSonTags.services.length)>0?true:false  }"></span>
							<span slot="label">更多筛选</span>
							<ht-business :type="2" :name="'品牌连锁'" @changeList="changeList" ref='hotel'></ht-business>
							<ht-business :type="3" :name="'设施服务'" @changeList="changeList"></ht-business>
						</el-tab-pane>
					</el-tabs>
				</div>
				<div class="bottom">
					<div class="bottom-left">
						<div class="bottom-left-top">
							<h2 v-if="htCnParams.cityId && searchHtData.list">找到{{searchHtData.total }}家</h2>
							<h2 v-if="htCnParams.cityId">酒店</h2>
							<div class="search-list">
								<el-tag v-for="tag in tagList" :key="tag.name" closable size="medium" effect="dark"
									@close="closeTag(tag)">
									{{tag.name}}
								</el-tag>
								<span class="removeAll" v-if="tagList.length" @click="removeAll">全部清除</span>
							</div>
							<div class="search-title">
								<el-select v-model="sortModel" @change="sortChange" placeholder="默认排序">
									<el-option v-for="item in sortOptions" :key="item.value" :label="item.name"
										:value="item.value">
									</el-option>
								</el-select>
							</div>
						</div>
						<div calss="bottom-left-bottom">
							<div class="htcn-list" v-show="searchHtData.total!==0">
								<div v-for="(item, index) in hotelList" :key="item.jdid">
									<div class="item">
										<img :src="item.img?item.img:img"/>
										<div class="item-center">
											<div class="center-top">
												<p>{{ item.jdmc }}</p>

												<el-rate :colors="['#FF9524', '#FF9524', '#FF9524']" :disabled="true"
													:value="Number(item.category)"></el-rate>
											</div>
											<div class="center-center">
												<p style="max-width: 400px;text-align: left;">{{ item.address }}</p>
												<span style="display: inline-block;width: 50px;" @click="getMapInfo(item)">查看地图</span>
												<span
													style="display: inline-block;width: 44px;"
													v-if='item.bussType==1 ||  item.bussType==2 || item.corpGroup == 1'
													class="icon-xieyi">协议</span>
											</div>
											<div class="center-bottom">
												<span v-for="(iconItem, index) in item.facilities" :key="index">
													<span  v-if="facility[iconItem]" class="iconYs">{{facility[iconItem]['title']}}</span>
												</span>
											</div>
										</div>
										<div class="item-right">
											<div class="right-top">
												<p class="p1">￥{{ item.price }}</p>
												<p class="p2">起</p>
											</div>
											<!-- 	<div class="btn" @click="openCollapse(item,index)" > {{item.expand?'收起':'展开报价'}}</div> -->
											<div class="btn" @click="openCollapse(item,index)" v-if="!item.expand">
												展开报价
											</div>
											<div class="btn" @click="hideCollapse(item,index)" v-if="item.expand">
												收起
											</div>
										</div>
									</div>
									
									<div  :style="item.expand?getShow(item):getHide()">
										<div v-if="item.children.length>0">
											<div v-for="rItem in item.children" :key="rItem.roomId" class="roomsCon"
												style="margin-bottom: 10px;">
												<div class="rItemTitle">
													{{rItem.roomName}}
												</div>
												<div class="rItemName">
													<span style="width: 200px;">产品名称</span>
													<span style="width: 100px;">早餐</span>
													<span style="width: 140px;">取消/担保规则</span>
													<span style="width: 100px;">日均价</span>
													<span style="width: 80px;">差旅标准</span>
													<span style="width: 120px;"></span>
												</div>
												<div v-for="(hItem, hIndex) in rItem.ratePlans" :key="hIndex">
													<div class="rItemtext">
														<span style="width: 200px;">{{hItem.rpName}}</span>
														<span style="width: 100px;">
															{{hItem.freeMeal | breakfastTypeName}}
														</span>
														<span style="width: 140px;color: #ff6c00;">
															<el-tooltip effect="light"
																:content="hItem.prepayRule.description" placement="bottom">
																<span v-if="hItem.shortRule==11">无需担保</span>
																<span v-if="hItem.shortRule==12">限时担保</span>
																<span v-if="hItem.shortRule==13">强制担保</span>
																<span v-if="hItem.shortRule==21">免费取消</span>
																<span v-if="hItem.shortRule==22">限时取消</span>
																<span v-if="hItem.shortRule==23">不可取消</span>
															</el-tooltip>
														</span>
														<span style="width: 100px;">¥{{hItem.averageRate}}</span>
														<span style="width: 80px;color: #ff6c00;"> {{jdjgbz >=hItem.averageRate? '': '违背'}}</span>
														<span style="width: 120px;">
															<!-- <span v-if="hItem.payment==1" class="fkBtn">现付</span>
															<span v-if="hItem.payment==2" class="fkBtn">预付</span> -->
															<span class="ydBtn"
																@click="htYDInfo(item,rItem,hItem)">预订</span>
														</span>
													</div>
												</div>
											</div>								
										</div>
										<div v-if="item.children.length==0">
											<div class="roomsCon">
												<div class="rItemTitle">
													该酒店未发布有效的价格，或酒店在入住时间段内满房！
												</div>
											</div>
										</div>
									</div>

								</div>
							</div>
							<el-empty style="margin-bottom: 70px" v-show="searchHtData.total==0"
								description="未找到合适的酒店，请重新搜索"></el-empty>
							<el-pagination v-if="searchHtData.total" @current-change="handleCurrentChange" background
								layout="prev, pager, next" :total="searchHtData.total"></el-pagination>
						</div>
					</div>
					<div class="bottom-right" :class="{psFixed:fixed}">
						<ht-cn-amap :mapType="mapType" :zoomInAndOut="zoomInAndOut" :currentMap="currentMap" :searchList="searchHtData" >
						</ht-cn-amap>
					</div>
				</div>
			</el-main>
		</el-container>
                		<!-- 违背原因选择 -->
		<yj-violation 
			:vltShow="isShowWeiBei" 
			:vltReason="violateitem" 
			byNumber="10903" 
			@chooseReason="chooseReason"
			@cancelReason="cancelReason"
			></yj-violation>
	</div>
</template>

<script>
	import {
		mapState,
		mapMutations,
		mapGetters
	} from 'vuex'
	import htStart from '@/views/htCn/HtCnComponent/htStart';
	import htBusiness from '@/views/htCn/HtCnComponent/htBusiness';
	import htCnAmap from '@/views/htCn/HtCnComponent/htCnAmap';
	export default {
		name: "HotelList",
		data() {
			return {
				fixed: false,
                isShowWeiBei:false,
                violateItem:'',
				hotelList: [], //酒店列表
				htIndexList: [], // 选中酒店展开房间下标
				roomList: [], //房间信息
				searchHtData: {}, // 搜索酒店数据
				cityId: '', // 城市id
				mapType: 1, //地图
				htLoading: false,
                htInfoParms:{},//emit返回的参数
				tagList: [],
				activeName: '',
				sortModel: '',
				zoomInAndOut: false, //点击文案地图放大缩小
				currentMap:{}, //点击列表获取当前酒店新增地址 及 坐标定位
				img:require('@/assets/image/banner/a2.png'),
				sortOptions: [
					//sort排序
					{
						name: '默认排序',
						value: ''
					},
					{
						name: '星级从高到低',
						value: 22
					},
					{
						name: '星级从低到高',
						value: 21
					},
					{
						name: '价格从低到高',
						value: 31
					},
					{
						name: '价格从高到低',
						value: 32
					},
				],
				facility:{ //酒店包含的服务
					152: {
						title: "收费电脑",
						icon: "iconfont yj-icon-diannao_o"
					},
					153: {
						title: "免费电脑",
						icon: "iconfont yj-icon-diannao_o"
					},
					227: {
						title: "大堂提供上网电脑",
						icon: "iconfont yj-icon-diannao_o"
					},
					243: {
						title: "租借笔记本电脑",
						icon: "iconfont yj-icon-diannao_o"
					},
					160: {
						title: "健身中心",
						icon: "iconfont yj-icon-jianshen"
					},
					22: {
						title: "免费班车接送",
						icon: "iconfont yj-icon-car-full"
					},
					242: {
						title: "免费接机服务",
						icon: "iconfont yj-icon-jh_hbh"
					},
					269: {
						title: "收费接机服务",
						icon: "iconfont yj-icon-jh_hbh"
					},
					197: {
						title: "免费停车",
						icon: "iconfont yj-icon-tingchechang"
					},
					198: {
						title: "收费停车",
						icon: "iconfont yj-icon-tingchechang"
					},
					225: {
						title: "中餐厅",
						icon: "iconfont yj-icon-canpinhui-xican"
					},
					226: {
						title: "西餐厅",
						icon: "iconfont yj-icon-canpinhui-xican"
					},
					233: {
						title: "日餐厅",
						icon: "iconfont yj-icon-canpinhui-xican"
					},
					234: {
						title: "中西自助餐厅",
						icon: "iconfont yj-icon-canpinhui-xican"
					},
					235: {
						title: "旋转餐厅",
						icon: "iconfont yj-icon-canpinhui-xican"
					},
				},
						
			}
		},
		props: {
			list: {
				type: Array,
				default () {
					return [];
				}
			},
			cxrList: {
				type: Array,
				default () {
					return [];
				}
			},
			search: {
				type: Boolean,
				default: false,
			}
		},
		components: {
			'ht-start': htStart,
			'ht-business': htBusiness,
			'ht-cn-amap': htCnAmap,
		},
		computed: {
			...mapState({
				htCnSonTags: (state) => state.htCn.htCnSonTags,
				htCnParams: state => state.htCn.htCnParams,
				htCnQuery: (state) => state.htCn.htCnQuery, //
				htCnPolicy: (state) => state.htCn.htCnPolicy
			}),
            			// 按差旅标准管控 酒店价格标准 
			jdjgbz() {
				let price
				if(JSON.stringify(this.htCnPolicy)!='{}'){
					price = this.htCnPolicy.policy.jdjgbz || ''
				}
				return price || ''
			},
		},
		watch: {
			htCnSonTags: {
				handler(val) {
					this.tagList = []
					for (let key in val) {
						val[key].forEach((element) => {
							this.tagList.push(element)
						})
					}
				},
				deep: true,
				immediate: true,
			},
			search: {
				handler(bool) {
					if (bool) {
						this.searchAll()
					}

				},
				deep: true,
				immediate: true,
			}
		},
		methods: {
			...mapMutations({
				SET_HTCN_QUERY: 'htCn/SET_HTCN_QUERY', // 保存因共模式下的自定义信息
				SET_HTCN_PARAMS: 'htCn/SET_HTCN_PARAMS', // 设置查询参数
				SET_HTCN_CCSQD: 'htCn/SET_HTCN_CCSQD', //保存因共模式下的出差申请单信息
				SET_CCSQD_INFO: 'common/setCcsqdInfo', // 全局的出差申请单信息
				SET_HTCN_SONNTAGS: 'htCn/SET_HTCN_SONNTAGS',
				SAVE_HOTELINFO: 'htCn/SAVE_HOTELINFO',
                SET_HTCN_POLICY: 'htCn/SET_HTCN_POLICY', // 保存差旅标准
			}),

			getScroll(event) {
				if(event.target.scrollTop>154){
					this.fixed = true;    
				}else{
					this.fixed = false;
				}    
			},
            chooseReason(res){
                this.htInfoParms.wbyy = res.reasonDesc;
                this.htInfoParms.reasonCode=res.reasonCode
                this.$emit('htInfo', this.htInfoParms)
                this.isShowWeiBei=false
            },
            cancelReason(res){
                
                this.isShowWeiBei=false

            },
			
			getHide() {
				let obj = {};
				obj = {
					height: 0 + 'px',
					visibility: 'hidden',
					transition:'height .3s linear'
				}
				return obj
			},
			// 获取高度
			getShow(item) {
				let list = item.children;
				let height = 0;
				if(list.length == 0){
					height = 51;
				}else{
					list.forEach((lItem,i)=>{
						if(lItem.ratePlans.length>0){
							height += lItem.ratePlans.length * 59 + 40 + 51
						}
					})
				}
				let obj = {};
				obj = {
					height: height+'px',
					visibility: 'visible',
					transition:'height .3s linear'
				}
				return obj
			},
			// 查询酒店
			getInputObj() {
				this.searchAll();
			},
			// 查询酒店
			changeList() {
				this.searchAll();
			},
			// 全部清除
			removeAll() {
				this.tagList = []
				this.$refs.hotel.removeAll()
				this.SET_HTCN_SONNTAGS({
					start: [],
					input: [],
					hotel: [],
					services: [],
				})
				this.SET_HTCN_PARAMS({
					starRate: '',
					priceRange: '',
					propertyIds: '',
					brandIds: '',
				})
				// 清除数据
				this.searchAll()

			},
			// 点击地图
			getMapInfo(item){
				this.zoomInAndOut = this.zoomInAndOut?false:true;
				this.currentMap = item;
			},
			closeTag(val) {
				// 关闭单个tages
				if (val.fitlerTypeByOne == 'start') {
					let index = this.$refs.start.startList.indexOf(val)
					this.$refs.start.startList[index].bOn = false
				} else if (val.fitlerTypeByOne == 'input') {
					this.$refs.start.form.min = ''
					this.$refs.start.form.max = ''
					this.$refs.start.priceBlur()
				} else if (val.fitlerTypeByOne == 'hotel') {
					this.$refs.hotel.removeHotel(val)
				} else if (val.fitlerTypeByOne == 'services') {
					this.$refs.hotel.removeServices(val)
				}
			},
			
			// 排序
			sortChange() {
				// 排序
				this.SET_HTCN_PARAMS({
					sort: this.sortModel
				})
				this.searchAll()
			},
			handleCurrentChange(val) {
				// this.htCnParams.pageNum=val
				this.SET_HTCN_PARAMS({
					pageNum: val,
				})
				this.searchAll()
			},
			// 展开报价
			openCollapse(item, index) {
            
				let params = {
					"checkInDate": this.htCnParams.checkInDate,
					"checkOutDate": this.htCnParams.checkOutDate,
					"hotelId": item.jdid,
				}
                this.getHtClbz()
				this.hotelList[index].expand = true;
				if (this.hotelList[index].children.length == 0) {
					this.getHotelInfo(params, index);
				} else {
					this.hotelList[index].children = item.children;
					this.$forceUpdate();
				}
				

			},
			hideCollapse(item, index) {
				this.hotelList[index].expand = false;
				this.$forceUpdate();
			},
			// 获取酒店信息
			getHotelInfo(params, index) {
				this.$conn.getConn('htbook.planSearch')(params, (res) => {
					let data = res.data || {};
					if(JSON.stringify(data)!='{}'){
						this.hotelList[index].children = data.rooms;
						
					}
					
					this.$forceUpdate();
				}, err => {

				})
			},
			// 酒店预定回显
			htYDInfo(item, rItem, hItem) {
                console.log(item, rItem, hItem,"item, rItem, hItem")
                let isWb=this.jdjgbz >=hItem.averageRate? 1:2 // '未违背': '违背'
                let extendInfo={
                    jdid: item.jdid,
					roomId: rItem.roomId,
					payment: hItem.payment,
					ratePlanId: hItem.ratePlanId,
					fyid: hItem.fyid,
					hotelId: item.jdid,
					bussType: 1,
                    img:item.img,
                    address:item.address,
                    jdmc:item.jdmc,
                }
                this.htInfoParms={
                    cabin : rItem.roomName ,
                    fyys :hItem.averageRate ,
                    dj :hItem.averageRate ,
                    flightNo : item.jdmc ,
                    fyid :hItem.fyid,
                    cabinCode : rItem.roomId  ,
                    priceId : hItem.ratePlanId ,
                    jdid :item.jdid  ,
                    queryFlag : 1,
                    extendInfo,
                    against:this.jdjgbz >=hItem.averageRate? 0:1
                }
								
                if(isWb==1){
                    this.$emit('htInfo', this.htInfoParms)
                }else{
									this.htInfoParms.violateitem="超过" + this.jdjgbz + "元/夜"
									this.htInfoParms.violateitemCode= "11109",
									this.violateitem="超过" + this.jdjgbz + "元/夜"
									this.violateitemCode= "11109",
									this.isShowWeiBei=true
                }
				// this.$emit('htInfo', obj)
			},
            // 单订模式下 因公匹配差旅标准 查询的时候可以理解为单定模式
			getHtClbz() {
				console.log("差旅标准被触发了么？")
				let nowDays=this.$moment().format("YYYY-MM-DD")
				let nextDays=this.$moment().add(1,"days").format("YYYY-MM-DD")
				let parsms = {
					cityId: this.htCnParams.cityId, //城市或者商圈id
					dateStart: this.htCnParams.checkInDate ||  nowDays, //日期开始时间
					dateEnd: this.htCnParams.checkOutDate || nextDays, // 日期结束时间
				}

				let userInfo = this.$common.sessionget('user_info');
				parsms.corpId = userInfo.empInfo.corpId;
				
				// 获取最低员工职级
				let cxrList = this.cxrList || [], allRank = [],
					minEmpRank = 999999; //企业设置的最低职级;
				let ids = [];
				for(let k=0; k < cxrList.length; k++){
					if (cxrList[k]['empRank'] == void 0) cxrList[k]['empRank'] = 0;
					if(cxrList[k]['empRank'] < minEmpRank){
						minEmpRank = cxrList[k]['empRank'];
					}
					allRank.push(cxrList[k]['empRank']);
					ids.push(cxrList[k]['empId'])
				}
				parsms.minEmpRank = minEmpRank;
				parsms.allRank = allRank.join(',');
				parsms.travelerIds = ids;

				this.$conn.getConn('htbook.travelPolicy2')(parsms, (res) => {
					let data = res.data || [];
					let policyInfo = !!data.policy ? {policy: data.policy} : {policy: {}};
					// data.forEach((item, index) => {
					// 	if (this.htCnQuery.empRank >= item.empRankS && this.htCnQuery.empRank < item.empRankE) {
					// 		policyInfo = item
					// 	} else if (this.htCnQuery.empRank >= item.empRankE) {  //汪经理说当匹配不到职级的时候取最低职级范围来判断
          //                   let sortData=data.sort((a,b)=>a.empRankS-b.empRankS)
					// 		policyInfo = sortData[0]
					// 	}
					// })
					this.SET_HTCN_POLICY(this.$common.deepCopy(policyInfo))
				}, (err) => {

				})
			},
			// 搜索酒店信息
			searchAll() {
				if (!this.htCnParams.cityId) {
					this.$message({
						type: 'error',
						message: '请先输入城市',
					})
				}
				if (!this.htCnParams.checkInDate || !this.htCnParams.checkOutDate) {
					this.$message({
						type: 'error',
						message: '请先输入日期',
					})
				}
				if (this.htCnParams.cityId && this.htCnParams.checkInDate && this.htCnParams.checkOutDate) {
					this.htLoading = true
					let parsms = {
						brandIds: this.htCnParams.brandIds, //品牌id，多个以逗号隔开 ,
						cityId: this.htCnParams.cityId, //城市或者商圈id
						checkInDate: this.htCnParams.checkInDate, //日期开始时间
						checkOutDate: this.htCnParams.checkOutDate, // 日期结束时间
						businessZone: this.htCnParams.businessZone, //商圈id，searchType=2时，必传 ,
						propertyIds: this.htCnParams.propertyIds, //服务设施
						district: this.htCnParams.district, // 行政区ID
						keywords: this.htCnParams.keywords, //关键字
						lat: this.htCnParams.lat,
						lon: this.htCnParams.lon,
						sort: this.htCnParams.sort, //排序
						jdType: this.htCnParams.jdType, //是否是协议
						pageNum: this.htCnParams.pageNum, //当前页数，不能小于1 ,
						propertyIds: this.htCnParams.propertyIds, //服务设施id，多个以逗号隔开 ,
						priceRange: this.htCnParams.priceRange, //价格范围：不传代表不限；范围以-隔开；如果是150元以下，传0-150；如果是800元以上，传800-999999 ,
						searchType: this.htCnParams.searchType, //查询类型，1 按行政区；2 按商圈；3 按经纬度；4 指定酒店附近；默认 0 ,
						starRate: this.htCnParams.starRate, //挂牌星级：不限就不传；二星级以下,传2；三星传3A；以此类推,多个以英文逗号隔开 ,
						keywords: this.htCnParams.keywords, //酒店名称、或酒店地址中包含的关键字
						count: 10,
					}
					this.$conn.getConn('htbook.hotelListSearch')(parsms, (res) => {
						if (res.httpStatus = 200) {
							let data = res.data || {};
							this.searchHtData = data;
							if(data.list.length>0){
								this.hotelList = data.list.map((item) => {
									
									let arr = [];
									if(item.facilities){
										arr = item.facilities.split(",");
										for(var i = 0; i < arr.length; i++) {
											// i - 1 ,因为空元素在数组下标 2 位置，删除空之后，后面的元素要向前补位，
											// 这样才能真正去掉空元素,觉得这句可以删掉的连续为空试试，然后思考其中逻辑
										   if(!arr[i]) {
												arr.splice(i,1);
												i = i - 1;	
										    }
										}
									}
									item.facilities = arr;
									item.expand = false;
									item.children = [];
									return item
								})
							}
							
						}
						this.$forceUpdate();
					}, (err) => {
						this.$message({
							type: 'error',
							message: err.tips,
						})
						this.htLoading = false
					})
				}

				this.$emit('clearSearch')
			}
		},
		filters: {			breakfastTypeName(val=0) {
				let retStr = '';				if (val == 1 || val == 2) {					retStr = val + '份早';				} else if (val > 2) {					retStr = '多份早';				} else {					retStr = '不含早'				}				return retStr			}		}
	}
</script>

<style lang="scss" scoped>
	.htlist {
		height: 530px;
		background-color: #eef1f6;
		overflow-y: auto;
		font-family: '微软雅黑';
		.el-main{
			overflow: initial;
		}
		.center-main {
			.bottom {
				background-color: white;
				margin-bottom: 10px;
				position: relative;
				display: flex;
				justify-content: space-between;
				background-color: #eef1f6;

				.bottom-left {
					width: 780px;
					.bottom-left-top {
						background-color: #fff;
						box-sizing: border-box;
						padding: 16px 16px 16px 24px;
						display: flex;

						h2 {
							margin-top: 5px;
						}

						.search-list {
							flex: 1;
							margin-left: 24px;
							display: flex;
							width: 300px;
							flex-wrap: wrap;
							align-items: center;

							.el-tag--medium {
								margin-right: 10px;
								margin-top: 10px;
							}

							.removeAll {
								color: #007aff;
								font-size: 12px;
								margin-left: 20px;
								margin-top: 10px;
							}
						}

						.search-title {
							margin-top: 5px;
							margin-right: 5px;

							/deep/ .el-input__inner {
								border: 0;
								width: 126px;
								//  color: red;
							}
						}
					}

					.htcn-list {
						width: 100%;
						background-color: #eef1f6;
						margin-top: 10px;
						display: flex;
						flex-direction: column;
						margin-bottom: 70px;

						.roomsCon {
							background-color: #FFFFFF;

							.rItemTitle {
								font-size: 16px;
								// background-color: rgba(100, 200, 200, 0.2);
								background-color: rgba(0, 123, 255, 0.2);
								text-align: left;
								padding: 10px 20px;
							}

							.rItemName,
							.rItemtext {
								text-align: left;
								padding-left: 20px;

								span {
									display: inline-block;
								}
							}

							.rItemName {
								height: 40px;
								background-color: rgba(200, 200, 200, 0.2);
								line-height: 40px;
							}

							.rItemtext {
								padding: 12px 0;
								padding-left: 20px;
								cursor: pointer;

								.ydBtn {
									display: inline-block;
									width: 52px;
									height: 35px;
									background-color: #ff6c00;
									font-size: 14px;
									color: #fff;
									box-sizing: border-box;
									line-height: 35px;
									text-align: center;
									cursor: pointer;
								}

								.fkBtn {
									display: inline-block;
									width: 41px;
									height: 21px;
									background-color: #ffefe4;
									font-size: 12px;
									color: #ff6c00;
									box-sizing: border-box;
									line-height: 21px;
									text-align: center;
									margin-right: 10px;

								}
							}

							.rItemtext:hover {
								background-color: rgba(200, 200, 200, 0.2);
								transition: all 0.3s ease;
							}
						}

						.item {
							width: 100%;
							margin-top: 10px;
							height: 180px;
							background-color: #fff;
							// background-color: pink;
							display: flex;
							position: relative;

							img {
								width: 180px;
								height: 180px;
							}

							.item-center {
								margin-left: 20px;

								.center-top {
									margin-top: 15px;
									display: flex;
									align-items: center;

									p {
										font-size: 18px;
										font-weight: bold;
										margin-right: 20px;

									}

									p:hover {
										color: red;
										cursor: pointer;
									}

									img {
										width: 16px;
										height: 14px;
									}
								}

								.center-center {
									margin-top: 10px;
									display: flex;
									align-items: center;

									p {
										color: #808080;
										font-size: 12px;
									}

									span {
										font-size: 12px;
										color: #007aff;
										margin-left: 15px;
										cursor: pointer;
									}

									.icon-xieyi {
										padding: 2px 10px;
										color: #FFFFFF;
										background-color: #007aff;
										border-radius: 2px;
									}
								}

								.center-bottom {
									margin-top: 22px;
									display: flex;
									flex-wrap: wrap;
									width: 400px;
									.iconYs {
										height: 25px;
										border: 1px solid #ff9f39;
										padding: 2px;
										display: inline-block;
										box-sizing: border-box;
										color: #ff9f39;
										margin-right: 10px;
										margin-bottom: 5px;
										cursor: pointer;
									}
								}
							}

							.item-right {
								display: flex;
								flex-direction: column;
								justify-content: center;
								position: absolute;
								right: 20px;
								bottom: 20px;

								.right-top {
									display: flex;
									align-items: center;
									// justify-content: flex-end;
									justify-content: center;

									.p1 {
										color: #007aff;
										font-size: 24px;
										font-weight: bolder;
									}

									.p2 {
										font-size: 12px;
										color: #007aff;
									}
								}

								.btn {
									width: 115px;
									height: 40px;
									background-color: #007aff;
									color: white;
									font-size: 16px;
									display: flex;
									align-items: center;
									justify-content: center;
									margin-top: 10px;
									cursor: pointer;
								}

								.disable {
									background-color: #ccc;
									color: white;
								}
							}
						}
					}

				}
				.psFixed{
					position: fixed;
					right: 57px;
					top: 148px;
				}
				.bottom-right {
					background-color: #eef1f6;
					width: 310px;
					height: 400px;
					
					.ccsqdPe {
						width: 100%;
						height: 69px;
						background-color: #fff;
						margin-bottom: 20px;
						box-sizing: border-box;
						cursor: pointer;
						padding: 20px;

						span {
							font-size: 15px;
							font-weight: bold;
							color: #424242;
							// line-height: 69px;
						}

						// }
					}
				}
			}

			.filter {
				display: flex;
				justify-content: space-between;
				background-color: white;
				margin-top: 10px;
				margin-bottom: 10px;
				box-sizing: border-box;
				padding: 10px;
				border-radius: 6px;

				h3 {
					margin: 10px;
				}

				.tabs {
					flex: 1;
				}

				.spot {
					width: 10px;
					height: 10px;
					border-radius: 50%;
					display: inline-block;
					margin-right: 5px;
					background: #007aff;
				}

				/deep/ .el-tabs__item {
					display: flex;
					align-items: center;
				}

				/deep/ .el-tabs__nav {
					display: flex;
				}

				/deep/ .el-tab-pane {
					display: flex;
					align-items: center;
				}
			}



		}


	}
</style>
