<template>
	<div class="tql-mian">
		<el-card class="tr-title-right">
			<div class="tr-title-city">
				<span>
					<el-button type="text" class="iconfont yj-icon-train2huoche iconR18"></el-button>
					{{trCityQuery.depCity}} - {{trCityQuery.arrCity}}
				</span>
				<span class="tr-title-num">
					<span class="num">共</span>
					<span class="numColor">{{total}}</span>
					<span class="num">条</span>
				</span>
			</div>
		</el-card>
		<week-date :selectDate="selectDate" @chooseDate="chooseDate" v-if="showDate" :dateRangeObj="dateRangeObj"></week-date>
		<el-card class="tql-box">
			<span class="bfb-w13">班次</span>
			<span class="bfb-w13 tql-box-hover colorZT" @click="sortTrain(lift)">出发时间
				<span style="position: relative;">
					<span class="tql-icon-width tql-icon-t" :class="{'colorTheme-t':(lift==1)}"></span>
					<span class="tql-icon-width tql-icon-b" :class="{'colorTheme-b':(lift==2)}"></span>
				</span>
			</span>
			<span class="bfb-w10">车站</span>
			<span class="bfb-w14 tql-box-hover colorZT" @click="sortTrainTime(timeLen)">行驶时长
				<span style="position: relative;">
					<span class="tql-icon-width tql-icon-t" :class="{'colorTheme-t':(timeLen==1)}"></span>
					<span class="tql-icon-width tql-icon-b" :class="{'colorTheme-b':(timeLen==2)}"></span>
				</span>
			</span>
			<span class="bfb-w10">坐席</span>
			<span class="bfb-w17">价格</span>
			<span class="bfb-w13">差旅标准</span>
			<span class="bfb-w10">操作</span>
		</el-card>
		<train-part-loading class="tql-marTop" :loading="partLoading && trQueryStatus==1"></train-part-loading>
		<el-card class="tql-marTop" v-if="trainList.length>0 && trQueryStatus==2 ?true:false">
			<div v-for="(item, index) in trainList" :key="item.trainNo+''+index"
				class="tql-content">
				<span class="bfb-w13">
					<el-button type="text" class="tql-code">{{item.trainCode}}</el-button> 
					<train-stop-over :stopOverObj="item" :stapBool="stapBool" @queryStopOver="chooseStopOver(item)"></train-stop-over>
				</span>
				<span class="bfb-w13">
					<el-timeline>
						<el-timeline-item class="item-text-from">
							{{item.startTime}}
						</el-timeline-item>
						<el-timeline-item class="item-text-to">
							{{item.arriveTime}} 
							<sup style="font-size: 12px; color: #1C83ED; font-weight: 100;">{{item.arriveDays>0?'+'+item.arriveDays:''}}</sup>
						</el-timeline-item>
					</el-timeline>
				</span>
				<span class="bfb-w10">
					<div class="text-line">
						{{item.fromStationName}}
					</div>
					<div class="text-top">
						{{item.toStationName}}
					</div>
				</span>
				<span class="bfb-w14 " style="line-height: 34px;"><span class="el-icon-timer"
						style="line-height: 34px;padding-right:5px;color:#65C3B0;"></span>{{item.runTime}}</span>
				<span class="bfb-w50 item-border-left">
					<span v-if="item.seatList.length>0" style="display: inline-block;" class="bfb-w100">
						<span v-for="(seat, index) in item.seatList" :key="seat.seatType+''+index" class="item-price bfb-w100">
							<span class="bfb-w20">
								<div class="tr-name">
									{{seat.seatName}}
								</div>
							</span>
							<span class="bfb-35">
								<div>
									<span class="icon-color icon-padr5 icon-size">¥ </span>
									<span class="price-color price-size">{{seat.price}}</span>
									<span class="tpl-count">余{{ '('+seat.seatCount+')'}} 张</span>
								</div>
							</span>
							<!-- 差旅标准违背 -->
							<span class="bfb-w25" v-if="gkfs==2 || gkfs==0">
								<el-popover class="wb-color" placement="bottom" trigger="hover" :content="item.clzcStr">
									<span slot="reference">{{ (seat.wb==1 && tripType==1) && !ccsqdSearch?'违背':''}}</span>
								</el-popover>
							</span>
							<!-- 出差申请单违背 -->
							<span class="bfb-w25" v-if="gkfs==1">
								<el-popover class="wb-color" placement="bottom" trigger="hover" :content="item.clzcStr">
									<span slot="reference">{{ (seat.wb==1 && tripType==1) && !ccsqdSearch?'违背':''}}</span>
								</el-popover>
							</span>
							<span class="bfb-w20">
								<el-button  class="item-con" 
											:class="{   ydwbItem: seat.wb == 1 && item.gkfs==2 && tripType==1 && !ccsqdSearch,
														ydwbItem2: (seat.wb == 1 && item.gkfs != 2 && tripType==1 && !ccsqdSearch),
														ydwbItem3: (seat.wb == 0 || tripType==2) || ccsqdSearch,}"
											v-if="seat.seatCount>0"
									@click.stop="goToTrEdit(seat.wb,item,seat)">
									预订
								</el-button>
								<!-- 
								<el-button type="primary" class="item-con" v-if="seat.seatCount==0 && corpParams==1" @click="goToTicketGrabbing(item)" >	
									抢票
								</el-button> -->
								<div class="item-con line-h34 item-sx-color pointer"
									v-if="seat.seatCount==0 &&(corpParams==1 || corpParams==0)">
									<el-button style="width:80px;height: 30px;background-color: #CCCCCC;border: 0px; color:#FFFFFF;font-size: 14px;" >售馨</el-button>
									<!-- <el-popover placement="top"  trigger="hover" content="当前车票已售完">
										slot="reference"
									</el-popover> -->
								</div>
							</span>
						</span>				
					</span>
					<span v-else class="wcsTr">{{item.saleDateTime}}</span>
				</span>
			</div>
		</el-card>
		<el-card class="tql-marTop" v-if="trainList.length==0 && (trQueryStatus!=1)?true:false">
			<el-empty description="未找到符合条件的车次"></el-empty>
		</el-card>
		<yj-violation :vltShow="visible" :vltReason="clzcStr" :byNumber="10904" @chooseReason="chooseReason" @cancelReason="cancelReason">
		</yj-violation>
	</div>
</template>

<script>
	
	import {
mapMutations,
mapState
} from 'vuex';
import mixin from './../../../../mixin';
import TrainPartLoading from './TrainPartLoading';
import weekDate from './WeekDate';
import trainStopOver from './trainDialog/TrainStopOver';
	export default {
		name: 'TrainQueryList',
		mixins:[mixin.ccsqdMixin],
		props: {
			// 火车票筛选数据
			trScreenData: {
				type: Object,
				default () {
					return {}
				}
			},
			// 改签单搜索
			isOrderChange: { 
				type: Boolean,
				default: false,
			},
			//新建出差申请单搜索
			ccsqdSearch:{
				type: Boolean,
				default: false,
			},
			// 是否显示1周 日期 
			showDate:{
				type: Boolean,
				default: true,
			},
			// 火车票查询信息，出发到达站点
			trCityQuery:{
				type: Object,
				default () {
					return {}
				}
			},
		},
		data() {
			return {
				visible: false,
				trStopOverObj: {},
				trainList: [],
				lift: 0, //0默认不做排序 1升序从小到大 2降序 大到小
				timeLen: 0, //0默认不做排序  时间长短排序  1升序 2降序
				selectDate: '',
				clzcStr: '', //违背原因
				chooseTrainData: {}, // 选中火车票信息
				total:0,
				dateRangeObj:{},
				stapBool: false, //经停信息位置
			}
		},
		components: {
			'train-stop-over': trainStopOver,
			'week-date': weekDate,
			'train-part-loading':TrainPartLoading,
		},
		created(){
			//处理经停站
			if(this.$route.path == "/yjTravel/book/trRouter/trQuery"){
				this.stapBool = true;
			}
		},
		computed: {
			...mapState({
				trainData: state => state.train.trainData, // 查询数据
				searchData: state => state.train.trainQuery.searchData, // 查询搜索火车票数据
				trainYdInfo: state => state.train.trainYdInfo, // 选中火车票的预订信息
				cxrList: state => state.train.cxrList, // 出行人信息
			}),
			// 因公 因私
			tripType() {
				/* 默认当前因公因私*/
				return this.searchData.tripType
			},
			// 登录人信息
			userInfo() {
				let userInfo = this.$common.sessionget('user_info');
				return userInfo;
			},
			// 1单订模式  2出差申请单模式
			bookStyle() {
				let bookStyle = this.userInfo.vipCorp.bookStyle;
				return bookStyle
			},
			// 火车票管控方式
			gkfs(){
				return this.$common.getVipParamterByParNo(this.$store,10999, 0);
			},
			// 经停站信息
			stopOverObj() {
				return this.trStopOverObj
			},
			
			//火车票抢票是否开启"
			corpParams() {
				return this.$common.getVipParamterByParNo(this.$store,1001, 3);
			},
			// 查询火车票数据列表
			trList(){
				return this.trainData.trainList
			},
			// 是否可预订
			ifBook(){
				return this.trainData.ifBook
			},
			//出发日期
			depDate(){
				return this.searchData.depDate;
			},
			// 出行人数
			cxrListLength(){
				return this.cxrList.length;
			},
			// 局部加载
			partLoading(){
				return this.searchData.partLoading;
			},
			// 火车票查询状态
			trQueryStatus(){
				return this.searchData.trQueryStatus;
			}
		},
		watch: {
			depDate: {
				handler(val) {
					this.selectDate = val;
				},
				immediate: true		
			},
			//火车票列表
			trList: {
				handler(val) {
					this.trainList = val;
					this.total = val.length;
				},
				immediate: true,
			},
			// 火车票筛选条件对象
			trScreenData(val) {
				this.trScreenData = val;
				this.queryTrainScreenList(this.trScreenData)
			},
			// 切换因公因私
			tripType:{
				handler(val){
					this.getChangeDate()
				},
				immediate:true,
				
			},
			trQueryStatus:{
				handler(val){
					if(val==1){
						this.lift = 0;
						this.timeLen = 0;
					}
				},
				immediate:true,
			}
		},

		methods: {
			...mapMutations({
				SET_SearchData: 'train/setSearchData',
				SET_TrainYdInfo: 'train/setTrainYdInfo',
			}),
			// 抢票
			// goToTicketGrabbing(){
			// 	this.$message({
			// 		type: 'warning',
			// 		message: '根据铁路运输要求，抢票功能暂且关闭。'
			// 	})
			// },
			// 切换因公因私获取日期范围可预订
			getChangeDate(){
				if(this.isOrderChange){
					// this.searchData.changeTripType 改签单暂不控制出差申请单日期范围
					this.dateRangeObj = this.getBookDateRange(10904,2,(this.searchData.maxDay - 1));
				}else{
					if(this.searchData.tripType==1){
						this.dateRangeObj = this.getBookDateRange(10904,this.searchData.tripType,(this.searchData.maxDay - 1),this.searchData.ccsqdId,this.searchData.routeId);
					}else{
						this.dateRangeObj = this.getBookDateRange(10904,2,(this.searchData.maxDay - 1));
					}
				}
			},
			// 筛选查询条件 火车票列表
			queryTrainScreenList(trScreenData) {
				
				// 条件
				let screenTrainTypeList = []; // 筛选 火车票类型列表
				let screenTrainStartTimeList = []; //筛选 火车出发时间列表
				let screenTrainEndTimeList = []; //筛选 火车到达时间列表
				let screenTrainFromStationList = []; // 筛选 火车票出发站点列表
				let screenTrainToStationList = []; // 筛选 火车票到站点列表
				// 值
				let startTimeObj = {}; //出发时间对象
				let endTimeObj = {}; //到达时间对象
				let maxStartTime = ""; //出发最大时间
				let minStartTime = ""; //出发最小时间
				let maxEndTime = ""; //到达最大时间
				let minEndTime = ""; // 到达最小时间

				let newTrainData = []; // 筛选条件后的火车票列表
				let strList = []; // 使用筛选条件数据列表

				let currentTrainList = this.trainData.trainList;

				// 判断当前对象空表示没有筛选项
				if (JSON.stringify(trScreenData) != "{}") {
					// 筛选火车车次类型时间
					if (trScreenData.screenTrainTypeList.length > 0) {
						screenTrainTypeList = trScreenData.screenTrainTypeList.map((item) => {
							return item.text
						})
						strList = screenTrainTypeList.toString().replace(/\//g, ',').split(',');
					}
					// 按照火车出发时间筛选
					if (trScreenData.screenTrainStartTimeList.length > 0) {
						// 遍历数组时间，换成字符串
						screenTrainStartTimeList = trScreenData.screenTrainStartTimeList.map((item) => {
							let minNum = item.value.split("-")[0].replace(":", "");
							let maxNum = item.value.split("-")[1].replace(":", "");
							startTimeObj = {
								maxTime: maxNum,
								minTime: minNum,
							}
							return startTimeObj
						})
						// 筛选出最小时间 最大时间
						maxStartTime = Math.max.apply(Math, screenTrainStartTimeList.map((item) => {
							return item.maxTime
						}))
						minStartTime = Math.min.apply(Math, screenTrainStartTimeList.map((item) => {
							return item.minTime
						}))
						console.log(minStartTime, maxStartTime)
					}
					// 筛选火车到达时间
					if (trScreenData.screenTrainEndTimeList.length > 0) {
						// 遍历数组时间，换成字符串
						screenTrainEndTimeList = trScreenData.screenTrainEndTimeList.map((item) => {
							let minNum = item.value.split("-")[0].replace(":", "");
							let maxNum = item.value.split("-")[1].replace(":", "");
							endTimeObj = {
								maxTime: maxNum,
								minTime: minNum,
							}
							return endTimeObj
						})
						// 筛选出最小时间 最大时间
						maxEndTime = Math.max.apply(Math, screenTrainEndTimeList.map((item) => {
							return item.maxTime
						}))
						minEndTime = Math.min.apply(Math, screenTrainEndTimeList.map((item) => {
							return item.minTime
						}))
					}
					// 筛选出发城市
					if (trScreenData.screenTrainFromStationList.length > 0) {
						screenTrainFromStationList = trScreenData.screenTrainFromStationList.map((item) => {
							return item.fromStationCode
						})
					}
					// 筛选到达城市
					if (trScreenData.screenTrainToStationList.length > 0) {
						screenTrainToStationList = trScreenData.screenTrainToStationList.map((item) => {
							return item.toStationCode
						})
					}
					for (let i = 0; i < currentTrainList.length; i++) {
						let info = currentTrainList[i];

						if ((strList.length > 0) && strList.indexOf(info.trainType) == -1) {
							continue
						}
						if ((minStartTime || maxStartTime) && !((info.startTime.replace(":", "")) >= minStartTime && (info.startTime.replace(":", "")) < maxStartTime)) {
							continue
						}
						if ((minEndTime || maxEndTime) && !((info.arriveTime.replace(":", "")) >= minEndTime && (info.arriveTime.replace(":", "")) < maxEndTime)) {
							continue
						}
						if ((screenTrainFromStationList.length > 0) && (screenTrainFromStationList.indexOf(info
								.fromStationCode) == -1)) {
							continue
						}
						if ((screenTrainToStationList.length > 0) && (screenTrainToStationList.indexOf(info
								.toStationCode) == -1)) {
							continue
						}
						newTrainData.push(info);
					}
				
					this.getScreenTrainData(newTrainData)
				} else {
					this.trainList = this.trainData.trainList;
					this.getScreenTrainData(this.trainList);

				}
				this.lift = 0;
				this.timeLen = 0;
			},

			// 筛选出新的火车票数据
			getScreenTrainData(newTrainData) {
				this.trainList = newTrainData;
				this.total = newTrainData.length;
				this.$emit('screenResult', newTrainData)
			},
			// 火车发车时间排序
			sortTrain(lift) {
				this.lift = (lift == 1 || lift == 0 ? 2 : 1);
				this.timeLen = 0;
				this.trainList.map((item) => {
					item.startTime = item.startTime.replace(":", "");
					return item
				})
				if (this.lift == 1) {
					this.riseTrainList(this.trainList)
				} else if (this.lift == 2) {
					this.dropTrainList(this.trainList)
				}
			},
			// 升序火车票列表
			riseTrainList(list) {
				let arr = list.sort((a, b) => {
					return parseInt(a.startTime) - parseInt(b.startTime)
				})
				arr.map((item) => {
					item.startTime = item.startTime.substring(0, 2) + ":" + item.startTime.substring(2, 4)
					return item
				})
				this.trainList = arr
			},
			// 降序火车票列表
			dropTrainList(list) {
				let arr = list.sort((a, b) => {
					return parseInt(b.startTime) - parseInt(a.startTime)
				})
				arr.map((item) => {
					item.startTime = item.startTime.substring(0, 2) + ":" + item.startTime.substring(2, 4)
					return item
				})
				this.trainList = arr

			},
			// 火车票行驶时长排序
			sortTrainTime(timeLen) {
				this.timeLen = (timeLen == 1 || timeLen == 0 ? 2 : 1);
				this.lift = 0;
				if (this.timeLen == 1) {
					this.riseTimeTrainList(this.trainList)
				} else if (this.timeLen == 2) {
					this.dropTimeTrainList(this.trainList)
				}
			},
			// 行驶时长火车票列表升序
			riseTimeTrainList(list) {
				let arr = list.sort((a, b) => {
					return parseInt(a.runMinute) - parseInt(b.runMinute)
				})
				this.trainList = arr
			},
			// 行驶时长火车票列表降序
			dropTimeTrainList(list) {
				let arr = list.sort((a, b) => {
					return parseInt(b.runMinute) - parseInt(a.runMinute)
				})
				
				this.trainList = arr
			},
			// 搜索选中日期
			chooseDate(date) {
				this.SET_SearchData({
					search: true,
					allLoading: false,
					partLoading: true,
				})
				this.$emit('chooseDate', date)
			},
			// 选中显示经停站
			chooseStopOver(item) {
				this.trStopOverObj = item;
			},
			// 跳转订单填写页 预定 （违背，选中的车次，坐席信息）
			goToTrEdit(wb, item, seat) {
				// 新建出差申请单 略过
				if(!this.ifBook && !this.ccsqdSearch){
					var msg = this.trainData.notBookMsg;
					this.$alert(msg,'温馨提示',{
						type: 'warning' 
					})
					return false
				}
				
				// 此处处理 改签搜索 与 出差申请单搜索会用到该组件，此时火车票订票范围不可用
				if((this.cxrListLength<=0 || this.cxrListLength>9) && !this.ccsqdSearch && !this.isOrderChange && this.tripType==1){
					this.$alert('火车票人数订票范围1 — 9 人','温馨提示',{
						type: 'warning' 
					})
					return false
				}
				//管控方式 1只做提醒2不允许预订 ,
				if (item.gkfs == 2 && wb==1 && !this.ccsqdSearch) {
					this.$alert('当前车票违背差旅标准，不允许预订','温馨提示',{
						type: 'warning' 
					})
					return false
				}
				if (seat.seatCount < this.cxrList.length) {
					this.$alert('车票余数不足，请减少出行人或选择其他车次','温馨提示',{
						type: 'warning' 
					})
					return false
				}
				// 违背差旅标准
				let queryObj = {
						violateCode:wb?item.clzcCode:'',
						violateItem:wb?item.clzcStr:'',
						reasonCode: '',
						reasonDesc: '',
					}	
				// 存储违背差旅标准	
				this.SET_SearchData(queryObj)	
				

				// 选中的当前座位信息
				item.selectSeat = seat;
				// 火车票数据信息
				this.chooseTrainData = item;
				
				// 火车票费用
				let currentFee = {};
				this.trainData.feeList.map(items => {
					if (items.prodType == 1) {
						currentFee = items;
					}
				})
				// 服务费
				item.fee = currentFee;
				// 违背原因
				item.reason = {
					reasonCode: '',
					reasonDesc: '',
				}
				
				// 存储火车票预定信息
				this.SET_TrainYdInfo(item)
				// 违背差标选择原因
				if (wb == 1 && this.tripType == 1 && !this.ccsqdSearch) {
					this.clzcStr = item.clzcStr;
					this.visible = true;
				} else {
					//改签单 与 新建出差申请单 不跳转
					if (this.isOrderChange || this.ccsqdSearch) {
						this.$emit('close', false, item,seat)			
					} else {
						// let routeUrl = this.$router.resolve({
						// 	path: "TrEdit"
						// });
						this.$router.push({path: "/yjEdit/trEdit"})
						// window.open(routeUrl.href, '_blank')
					}
				}
			},
			// 选中违背原因
			chooseReason(chooseReasonObj) {
				if (!chooseReasonObj.reasonCode) {
					this.visible = false;
				} else {
					let trainData = this.trainYdInfo;
					this.SET_SearchData(chooseReasonObj)
					this.visible = false;
					// 出差申请单查询 或者 改签单查询 不跳转
					if ( this.isOrderChange || this.ccsqdSearch) {
						trainData.chooseReasonObj=chooseReasonObj
						this.$emit('close', false, trainData,trainData.selectSeat)
					} else {
                        // let routeUrl = this.$router.resolve({
                        // 	path: "TrEdit"
                        // });
						this.$router.push({path: "/yjEdit/trEdit"})
                        // window.open(routeUrl.href, '_blank')
					}
				}
			},
			//关闭违背
			cancelReason(bool){
				this.visible = bool;
			}
		}
	}
</script>

<style lang="scss" scoped>
	/* bfb-w  表示百分比宽度*/
	.tql-mian {
		color: #424242;
		font-family: '微软雅黑';
		.tr-title-right {
			margin-bottom: 15px;
			/deep/ .el-card__body {
				padding: 20px;
			}
			.tr-title-city {
				display: flex;
				justify-content: space-between;
				line-height: 14px;
				font-size: 15px;
				font-weight: 400;
				color: #424242;
		
				.iconR18 {
					padding: 0 10px 0 0;
					font-size: 16px;
					// color: #006BB9;
				}
		
				.tr-title-num {
					font-size: 15px;
					font-weight: 400;
		
					.num {
						color: #b3b3b3;
					}
		
					.numColor {
						color: #424242;
					}
				}
			}
		}
		.bfb-w10 {
			width: 10%;
		}

		.bfb-w13 {
			width: 13%;
		}

		.bfb-w14 {
			width: 14%;
		}

		.bfb-w17 {
			width: 17%;
		}

		.bfb-w20 {
			width: 20%;
		}

		.bfb-w25 {
			width: 25%;
		}

		.bfb-35 {
			width: 35%;
		}

		.bfb-w50 {
			width: 50%;
		}

		.bfb-w100 {
			width: 100%;
		}

		.pointer {
			cursor: pointer;
		}

		.el-card.is-always-shadow {
			border: 1px solid #E6E6E6;
			box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.02);
			border-radius: 0;
		}

		.tql-box {
			/deep/ .el-card__body {
				padding: 13px 20px;
			}

			text-align: left;
			width: 100%;
			margin-top: 15px;

			span {
				display: inline-block;
			}

			.tql-box-hover:hover {
				cursor: pointer;
			}

			.tql-icon-width {
				width: 0;
				height: 0;
				position: absolute;
				font-size: 18px;
			}

			.tql-icon-t {
				border-left: 6px solid transparent;
				border-right: 6px solid transparent;
				border-bottom: 8px solid #ccc;
				bottom: 7px;
			}

			.tql-icon-b {
				border-left: 6px solid transparent;
				border-right: 6px solid transparent;
				border-top: 8px solid #ccc;
				top: -3px;
			}

			.colorZT {
				color: #1C83ED;
			}

			.colorTheme-t {
				border-bottom: 8px solid #1C83ED;
			}

			.colorTheme-b {
				border-top: 8px solid #1C83ED;
			}
		}

		.tql-marTop {
			margin-top: 15px;
			/deep/ .el-card__body{
				padding: 20px;
			}
			.tql-content {
				width: 100%;
				margin-bottom: 20px;

				span {
					display: inline-block;
					text-align: left;
					vertical-align: top;
				}
				.wcsTr{
					width:100%;
					line-height: 34px;
					display: inline-block;
					text-align: right;
					padding-right: 8px;
				}
				.item-border-left {
					position: relative;
				}

				.item-border-left::before {
					content: '';
					position: absolute;
					left: -10px;
					border-left: 1px solid #EBEBEB;
					height: 90%;

				}

				.tql-code {
					font-size: 24px;
					// color: #4089FF;
					font-weight: bold;
					line-height: 22px;
					cursor: initial;
				}

				.item-text-from {
					font-size: 24px;
					line-height: 22px;
					font-weight: bold;
				}

				.item-text-to {
					font-size: 18px;
					line-height: 16px;
					font-weight: bold;
				}

				.text-line {
					font-size: 14px;

					display: inline-block;
					vertical-align: top;
				}

				.text-top {
					padding-top: 25px;
				}

				.item-price {
					line-height: 34px;
					// margin-bottom: 20px;

					.tr-name {
						letter-spacing: 1px;
					}

					.wb-color {
						color: red;
					}

					.wb-color:hover {
						cursor: pointer;
					}

					.price-color {
						color: #FF9024;
					}

					.price-size {
						font-size: 18px;
						font-weight: bold;
					}

					.icon-color {
						color: #B3B3B3;
					}

					.icon-padr5 {
						padding-right: 5px;
					}

					.icon-size {
						font-size: 14px;
					}
				}

				/deep/ .el-button {
					border-radius: 0;
					padding: 0;
				}

				/deep/ .el-button--small {
					padding: 0;
				}

				.item-con {
					color: #FFFFFF;
					text-align: center;
					width: 80px;
					height: 30px;
					margin-bottom: 20px;
					font-size: 14px;
					font-weight: 800;
					border: none;
					
				}
				.item-con:hover{
					opacity: 0.7;
				}
				
				.ydwbItem{
					    background: -webkit-gradient(linear,left top,right top,from(#FF9024),to(#FF4444));
					    background: -webkit-linear-gradient(left,#FF9024,#FF4444);
					    background: linear-gradient(90deg,#FF9024,#FF4444);
					// background: linear-gradient(135deg, #FF9024 0%, #FF0000 100%);
				}
				.ydwbItem2{
					background: #FF4444;
				}
				.ydwbItem3{
					background: #FF9024;
				}
				.line-h34 {
					line-height: 34px;
				}

				.item-sx-color {
					color: #FF9024;
				}

				.tpl-count {
					color: #333;
					font-size: 14px;
					padding-left: 5px;
				}
			}
		}
	}
</style>
