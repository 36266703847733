<template>
	<div class="hf-main">
		<div>
			<div class="hf-top">
				<!-- <div class="hf-top-item">
					<span class="item"><span class="red">*</span>出差类型</span>
					<div class="relative">
						<el-input v-model="ccProjectName" readonly></el-input>
						<i class="tql-icon-t absolute topR" @click="chooseIconTop(ccTypeNumber)"></i>
						<i class="tql-icon-b absolute bottomR" @click="chooseIconTop(ccTypeNumber)"></i>	
					</div>
				</div> -->
				<div class="hf-top-item" v-if="ruleForm.ccsqdId">
					<span class="item">申请单号</span>
					<div>
						<el-input v-model="ruleForm.ccsqdNo" readonly :disabled='allDisabled'></el-input>
					</div>
				</div>
				<div class="hf-top-item" v-if="ruleForm.ccsqdId"> 
					<span class="item">申请时间</span>
					<div>
						<el-input v-model="ruleForm.reqTime" readonly :disabled='allDisabled'></el-input>
					</div>
				</div>
				<div class="hf-top-item" v-if="showProject && isShowProject">
					<span class="item"><span class="red" v-if="projectRequired">*</span>出差项目</span>
					<el-input v-model="ruleForm.projectName" @click.native="queryProject" readonly></el-input>
				</div>
			</div>
			<div class="hf-center">
				<div class="hf-center-item">
					<div class="item"><span class="red">*</span>出差人</div>
					<el-input v-model="ruleForm.ccr" readonly  @click.native="queryCcrList" :disabled='allDisabled'></el-input>
				</div>
				<div class="hf-center-item">
					<span class="setLJdate"><span class="red">*</span>出差日期</span>
					<div class="cs-date">
						<el-date-picker
							:disabled='showPicker'
							:value-format="type == 0 ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH:mm'"
							:format="type == 0 ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH:mm'"
							v-model="ccsqdDateRange"
							type="datetimerange"
							:picker-options="pickerOptions"
							range-separator="至"
							start-placeholder="开始日期"
							end-placeholder="结束日期"
                            :default-time="['00:00:00', '23:59:00']"
							prefix-icon=" "
							:clearable="false"
							@input="getDateInfo"
						>
						</el-date-picker>
					</div>
				</div>
				<div class="hf-center-item">
					<span class="item">常驻地</span>
					<el-input v-model="ruleForm.mdd"  @change="inputValue" placeholder="多个城市使用/分隔" disabled></el-input>
				</div>
				<div class="hf-center-item">
					<span class="item">随行人 </span>
					<el-input v-model="ruleForm.sxr"  @click.native="querySxrList" readonly :disabled='allDisabled' v-if="!ruleForm.sxrNameSpilt"></el-input>
                    <el-input v-model="ruleForm.sxrNameSpilt"  @click.native="querySxrList" readonly :disabled='true' v-else></el-input>
				</div>
                <div class="hf-center-item" >
					<span class="item"><span class="red"  v-if="costCenterRequired">*</span>出差天数</span>
					<el-input v-model="diffTime" readonly disabled ></el-input>
				</div>
                <div class="hf-center-item" v-show="empInfo.travelArea==1">
					<span class="item"><span class="red" ></span>出差范围</span>
					<!-- <el-input v-model="ruleForm.area" readonly :disabled='allDisabled' ></el-input> -->
                    <el-select v-model="ruleForm.area" placeholder="请选择">
                        <el-option
                            v-for="item in areaList"
                            :key="item.label"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                </el-select>
				</div>
				<div class="hf-center-item" v-if="showCostCenter">
					<span class="item"><span class="red"  v-if="costCenterRequired">*</span>成本中心</span>
					<el-input v-model="ruleForm.costCenterName" @click.native="queryCostCenter" readonly :disabled='allDisabled'></el-input>
				</div>

			</div>
			<div class="hf-footer" v-show="false">
				<span class="item"><span class="red"></span>出差事由</span>
				<el-input v-model="ruleForm.ccsy" @change="inputValue" :disabled='allDisabled'></el-input>
			</div>
		</div>
		<!-- :noCxrList="currentCcrList"   -->
		<yj-cxr-list :visible="allVisible" 
					 :cxrList="allCxrList"
					 :noCxrList="currentCcrList"
					 :maxLength="allEmpType.empNumber"
					 :clkShow="clkShow"
					 @chooseCxrList="chooseCxrList" 
					 @cancleCxrList="chooseCancel"></yj-cxr-list>
		
		<!-- 成本中心 -->
		<el-dialog title="选择成本中心"  :visible.sync="costCenterVisible" style="text-align: left;" class="costDialog" >
			<div class="costheader">
				<div class="cost-tab">
					<span class="cost-ccr" @click="getCostStatus" :class="{'cost-color':costType==1}">出差人</span>
					<span class="cost-sxr" @click="getCostStatus" :class="{'cost-color':costType==2}">随行人</span>
					<span :style="chooseCostStyle()"></span>
				</div>
			</div>
			<div class="cost-item" v-if="costType==1">
				<div v-for="item in currentCcrList" :key="item.empId" class="costName">
					<span>{{item.empName}}</span>
					<el-select v-model="item.costCenterName" @change="getCcrCostCenter($event)" clearable filterable placeholder="请选择">
					    <el-option
					      v-for="costItem in costCenterList"
					      :key="costItem.costCenterId"
					      :label="costItem.costCenterName"
					      :value="costItem.costCenterId">
					    </el-option>
					</el-select>
				</div>
			</div>
			<div class="cost-item" v-if="costType==2">
				<div v-for="(item, index) in currentSxrList" :key="item.empId"  class="costName">
					<span>{{item.empName}}</span>
					<el-select v-model="item.costCenterName" @change="getSxrCostCenter($event,index)" clearable filterable  placeholder="请选择">
					    <el-option
					      v-for="costItem in costCenterList"
					      :key="costItem.costCenterId"
					      :label="costItem.costCenterName"
					      :value="costItem.costCenterId">
					    </el-option>
					</el-select>
				</div>
			</div>
		
			<span slot="footer" class="dialog-footer">
			    <el-button @click="costCenterVisible = false">取 消</el-button>
			    <el-button type="primary" @click="costCenterVisible = false">确 定</el-button>
			  </span>
		</el-dialog>
		<!-- 出差项目 -->
		<el-dialog title="出差项目"  :visible.sync="projectVisible" style="text-align: left;" class="proDialog" >
			<div class="flex first">
				<el-input placeholder="请输入出差项目名称或编码" v-model="projectWords">
					<el-button slot="append" icon="el-icon-search" @click="queryProject"></el-button>
				</el-input>
			</div>
			<el-table :data="projectList" :header-cell-style="{ backgroundColor: 'rgba(64,160,255,0.1)'}" class="ta-table">
				<el-table-column  width="50"  >
					<template slot-scope="scope" >
						<el-radio v-model="projectInfo.projectId" :label="scope.row.id" @click.native="currentApplyForm($event,scope.row)"><span></span></el-radio>
					</template>
				</el-table-column>
				<el-table-column prop="projectName" label="项目名称" width="150"></el-table-column>
				<el-table-column prop="projectCode" label="项目编码" width="200"></el-table-column>
				<el-table-column prop="savedate" label="创建时间" width="170"></el-table-column>
				<el-table-column prop="beginTime" label="启动时间" width="130"></el-table-column>
			</el-table>
			<div slot="footer" class="dialog-footer">
			    <el-button type="primary" @click="projectDetermine">确 定</el-button>
			</div>
			<el-pagination
				class="textLeft"
				background
			    @current-change="projectChange"
			    :current-page.sync="projectPage.pageNum"
			    :page-size="projectPage.count"
			    layout="total, prev, pager, next"
			    :total="projectPage.total">
			</el-pagination>
		</el-dialog>
	</div>
</template>

<script>
	import {mapState,mapGetters} from 'vuex';
	export default{
		name: 'CcsqdHeader',
		props:{
			// 0标准版 1 希安康
			type: {
				type: String,
				default: '0',
			},
			detail:{
				type: Object,
				default(){
					return {}
				}
			},
			routeLength:{
				type: Number,
				default:0,
			},
            allDisabled:{
                type:Boolean,
                default:false
            }
		},
		
		data(){
			return {
				ccProjectTypeList:[ // 是否是出差项目
					{
						text: '非项目',
						id: '0',
					},
					{
						text: '项目',
						id: '1',
					}
				],
                areaList:[
                {
                    label:'区域内',
                    value:'1'
                },
                {
                    label:'区域外',
                    value:'2'
                },
                ],
				ccProjectName: '', 
				ccTypeNumber:0, //出差类型 ， 默认0
				
				ruleForm:{
					appId: "", //审批规则id ,
					ccsy: '', //出差事由
					ccr: "", //出差人 ,
					ccsqdNo:'',// 单号
					ccsqdId: "",// 出差申请单id
					costCenterCode: "", // 成本中心编码
					costCenterId: "", // 成本中心id
					costCenterName: "", //默认成本中心
					dateBegin: "", //出差日始
					dateEnd: "", //出差日止
					mdd: "", //目的地
					project: "", //是否是出差项目
					projectCode: "", //出差项目编码
					projectId: "", // 出差项目id
					projectName: "", // 出差项目名称
					sxr: "", //随行人 
					reqTime:'', // 申请时间，
                    sxrNameSpilt:'',
                    area:'1',
				},
				
				allVisible: false, // 出行人控件显示
				allCxrList:[],  //所有出行人类型数据列表  用于区分出差人及随行人
				
				currentCcrList: [], // 当前出差人列表
				currentSxrList: [], // 当前随行人列表
				
				costType:1, //成本中心组件 出行人与随行人类型 用于切换出行人随行人
				
				costCenterVisible:false, //成本中心
				costCenterList: [], // 成本中心列表
				ccrRadio:'', // 出差人选中的成本中心
				defaultName: "first", // 默认选择成本中心名称
				sxrRadio:'', // 随行人选中的成本中心
				
				projectVisible: false,  // 是否显示出差项目
				projectList: [], // 出差项目列表
				projectRadio: '', // 选中的出差项目
				projectWords: '', //出差项目搜索数据 条件
				chooseProject: '',// 选中羡慕
				projectInfo:{
					projectName:'',
					projectId:'',
					projectCode:'',
				},
				projectPage:{
					pageNum:1,
					count:10,
					total:0,
				},
				isShowProject: false,// 选择项目是显示
				
				ccFormInfo:{  // 出差单补充信息
					cxr: "", //出差人使用逗号分割
					ccrId: "", //出差人id使用逗号分割
				},
				
				// defaultDate:[], // 默认时间
				
				allEmpType:{
					ccrType: false, 
					sxrType: false,
					empNumber:0,
				},
				
				pickerOptions: {
					disabledDate: (time) => {
                        if(this.param20035){

                             return 
                        }else{
                            return time.getTime() < Date.now() - 24 * 60 * 60 * 1000 
                        }
						
					}
				},
				clkShow: false, // 常旅客是否展示 ，true 显示 false 关闭
			}
		},

		computed:{
			...mapGetters({
				getVipParamterByParNo:'common/getVipParamterByParNo',
			}),
			userInfo(){
				let userInfo = this.$common.getUserInfo();
                console.log(userInfo,"userInfo")
				return userInfo;
			},
			empInfo(){
				return this.userInfo.empInfo;
			},
			vipCorp(){
				return this.userInfo.vipCorp;
			},
            param20035(){
                let param = this.getVipParamterByParNo(20035,0);
				return param==1;
            },
			// 显示项目
			showProject(){
				let param = this.getVipParamterByParNo(20003,3);
				return param==1;
			},
			// 显示成本中心
			showCostCenter(){
				let param = this.getVipParamterByParNo(20002,3);
				return param==1;
			},			
			// 出差项目 是否开启必填 0不开启  1开启
			projectRequired(){
				let param = this.getVipParamterByParNo(10996,3);
				return param==1 && this.showProject
			},
			 // 成本中心 是否开启必填 0不开启  1开启
			costCenterRequired(){
				let param = this.getVipParamterByParNo(10995,3);
				return param==1 && this.showCostCenter
			},
            showPicker(){ //答辩需求
                if(this.allDisabled && this.timeDisabled){
                    return true
                }else{
                    return false
                }
            },
			// 出差申请单开始和结束日期处理   
			// 包含  a：默认值处理 和 b:修改后赋值
			ccsqdDateRange:{
                
				get(){
                    console.log(this.ruleForm,"this.ruleForm")
					let begin = this.ruleForm.dateBegin || this.$common.getAppointDay(new Date(),0);
					let end = this.ruleForm.dateEnd || this.$common.getAppointDay(new Date(begin),1);
					if (this.type == 1) {
						let initBeginDate = this.$moment().format('yyyy-MM-DD') + ' ' + '00:00'; 
						let initEndDate = this.$moment().add(1, 'd').format('yyyy-MM-DD') + ' ' + '23:59';
						begin = this.ruleForm.dateBeginLong || initBeginDate;
						end = this.ruleForm.dateEndLong || initEndDate;
					}
					return [begin, end]
				},
				set(dateArr){
					this.ruleForm.dateBegin  = dateArr[0];
					this.ruleForm.dateEnd  = dateArr[1];
				}
			},
			diffTime:{
				get(){
					const m1 = this.$moment(this.$moment(this.ccsqdDateRange[0]).format('yyyy-MM-DD'));
					const m2 = this.$moment(this.$moment(this.ccsqdDateRange[1]).format('yyyy-MM-DD'));
					const day = m2.diff(m1, 'd');
					return day+1
				},
				set(){
	
				}
			},
		
		},
		mounted() {

			
			// 默认出差人
			this.setMemoryCcr()
			// 默认出差类型
			this.chooseIconTop(0);
			// 先返回出差申请单日期
			this.getDateInfo(this.ccsqdDateRange)
            this.ruleForm.mdd = this.empInfo.residenceCityName;
            
		},
		watch:{
			detail(info){
                console.log("被处罚")
				this.detailInfo(info)
			},
			// routeLength(num){
			// 	console.log(num)
			// }
		},
		methods:{
			
			// 选中项目 非项目 （只用于 两个选项）
			chooseIconTop(num){
				if(num==1 && this.showProject==true){
					this.isShowProject = true;
				}else{
					this.isShowProject = false;
				}
				
				this.ccProjectTypeList.forEach((item,index)=>{
					if(index==num && this.showProject){
						this.ruleForm.project = item.id;
						this.ccProjectName = item.text;
						this.ccTypeNumber= (num==0?1:0);
					}else if(!this.showProject){
						this.ruleForm.project = 0;
						this.ccProjectName = '非项目';
						return
					}
				})
			},
			// 默认出差人
			setMemoryCcr(){
				this.currentCcrList = [];
				this.empInfo.sxr = 0;
				this.currentCcrList.push(this.empInfo);	
				this.currentCxrList = this.currentCcrList;
				this.ruleForm.ccr = this.empInfo.empName;
				this.ccFormInfo.ccrId = this.empInfo.empId;
				this.ruleForm.costCenterCode= this.empInfo.costCenterCode;  // 成本中心编码
				this.ruleForm.costCenterId=  this.empInfo.costCenterId; // 成本中心id
				this.ruleForm.costCenterName=  this.empInfo.costCenterName; //默认成本中心
				this.$emit('ccProjectInfo',this.ruleForm,this.ccFormInfo);
				this.$emit('selectCxrList',this.currentCxrList); 
			},
			getDateInfo(dateArr){
				this.$emit('getCcsqdDateInfo',dateArr)
			},
			//获取成本中心中 出行人与随行人状态
			getCostStatus(){
				this.costType = (this.costType==1?2:1)
			},
			// 成本中心控件 选择出行人 随行人
			chooseCostStyle() {
				var styleObj = {
					position: 'absolute',
					left: `${(this.costType-0-1)*82}px`,
					top: 0,
					transition: 'all 0.4s ease',
					'z-index': 1,
					width: 82 + 'px',
					height: 28 + 'px',
					background: '#FFFFFF',
					'border-radius': 28 + 'px',
				}
				return styleObj;
			},
			//获取出差人成本中心
			getCcrCostCenter(event){
				let choosenItem = this.costCenterList.filter((item) => item.costCenterId === event)[0];
				this.currentCcrList.map((item,index)=>{
					item.costCenterCode = choosenItem.costCenterCode;
					item.costCenterName = choosenItem.costCenterName;
					item.costCenterId = choosenItem.costCenterId;
					this.ruleForm.costCenterName = choosenItem.costCenterName;
					this.ruleForm.costCenterCode = choosenItem.costCenterCode;
					this.ruleForm.costCenterId =  choosenItem.costCenterId;
					return item					
				})
			},
			// 获取随行人成本中心
			getSxrCostCenter(event,i){
				  let choosenItem = this.costCenterList.filter((item) => item.costCenterId === event)[0];
				  this.currentSxrList.map((item,index)=>{
						if(i == index){
							item.costCenterCode = choosenItem.costCenterCode;
							item.costCenterName = choosenItem.costCenterName;
							item.costCenterId = choosenItem.costCenterId;
						}
						return item
				  })
				  this.$forceUpdate();
			},
			// 选择出差人列表
			queryCcrList(){
                if(this.allDisabled){
                    return
                }
				this.allVisible = true;
				this.allEmpType.ccrType = true;
				this.allCxrList = this.currentCcrList;
				this.allEmpType.empNumber = 1;
				this.clkShow= false;
				
			},
			// 选中随行人列表
			querySxrList(){
				this.clkShow= true;
                if(this.allDisabled){
                    return
                }
				if(this.routeLength){
					this.$confirm('选择随行人会重置行程信息', '提示', {
					        confirmButtonText: '确定',
					        cancelButtonText: '取消',
					        type: 'warning',
					        center: false}).then(() => {
								this.allVisible = true;
								this.allEmpType.sxrType = true;
								this.allCxrList = this.currentSxrList;
								this.allEmpType.empNumber = 49;
							}).catch(() => {
					          
							});
				}else{
					this.allVisible = true;
					this.allEmpType.sxrType = true;
					this.allCxrList = this.currentSxrList;
					this.allEmpType.empNumber = 49;
				}
				
			},
			// 选中 分类 出差人 随行人 出行人
			chooseCxrList(list){
                if(this.allDisabled ){
                    return
                }
				let empNameList = [];
				let empIdList = [];
				list.forEach((item)=>{
					empNameList.push(item.cxrName);
					empIdList.push(item.empId);
				})
				if(this.allEmpType.ccrType){
					if(list.length>0){
						list[0].sxr = 0;
					}
					this.currentCcrList = list;
					this.currentCxrList = list;
					this.ruleForm.ccr = empNameList.join(",");
					this.ccFormInfo.ccrId = empIdList.join(",");

                    this.ruleForm.mdd = list[0]? (list[0].residenceCityName || ''): '';

					this.$emit('selectCxrList',this.currentCxrList); 
					this.allEmpType.ccrType = false;
				}else if(this.allEmpType.sxrType){
					this.currentSxrList = list;
					let arr = [];
					this.currentCxrList = arr.concat(this.currentCcrList,this.currentSxrList);
					this.ruleForm.sxr = empNameList.length>3?empNameList.slice(0,3).join(",")+'共'+empNameList.length+'人':empNameList.join(",");	
					this.allEmpType.sxrType = false;
					this.$emit('selectCxrList',this.currentCxrList);  
				}
				this.allVisible = false;
			},
			// 关闭出差人 随行人列表
			chooseCancel(bool){
				if(this.allEmpType.ccrType){
					this.allEmpType.ccrType = false;
				}else if(this.allEmpType.sxrType){
					this.allEmpType.sxrType = false;
				}
				this.allVisible = bool;
			},
			// 查询成本中心
			queryCostCenter(){
                if(this.allDisabled){
                    return
                }
				this.costCenterVisible = true;
				let queryParams = {};
				queryParams = {
					travelEmpId : this.currentCcrList[0].empId,
				}
				this.$conn.getConn('commu.costCenterList',{jsfile:false})(queryParams).then((res)=>{
					let data = res.data || {};
					this.costCenterList = data.list || [];
				}).catch((err)=>{
					this.$message({
						type: 'error',
						message: err.tips
					})
				})
			},
			// 随行人选择成本中心
			chooseSxrCostCenter(e){
				console.log(e)
			},
			// 查询出差项目
			queryProject(){
				this.projectVisible = true;
				let queryParams = {};
					queryParams = {
						count: this.projectPage.count ,
						keywords: this.projectWords ,
						pageNum: this.projectPage.pageNum,
					}
				this.$conn.getConn('commu.project',{jsfile:false})(queryParams).then((res)=>{
					let data = res.data || {};
					this.projectList = data.list || [];
					this.projectPage.pageNum = data.pageNum;
					this.projectPage.count = data.count;
					this.projectPage.total = data.total;
				}).catch((err)=>{
					this.$message({
						type: 'error',
						message: err.tips
					})
				})
			},
			// 选中当前出差项目
			currentApplyForm(el,row){
				if(el.target.tagName == 'INPUT'){
					return 
				}
				this.projectInfo = {
					projectId: row.id,
					projectCode: row.projectCode,
					projectName: row.projectName,
				}
			},
			// 确定选择出差新项目
			projectDetermine(){
				if(this.projectInfo.projectId){
					this.ruleForm.projectName = this.projectInfo.projectName;
					this.ruleForm.projectId = this.projectInfo.projectId;
					this.ruleForm.projectCode = this.projectInfo.projectCode;
				}else {
					this.projectInfo = {
						projectName:'',
						projectId:'',
						projectCode:'',
					}
				}
				this.projectVisible = false;
			},
			//出差项目分页
			projectChange(e){
				this.projectPage.pageNum = e;
				this.queryProject();
			},
			// 输入框
			inputValue(){
				this.$emit('allFormData',this.ruleForm)
			},
			// 详情赋值
			detailInfo(info){
                console.log(info,"info")
				this.ruleForm = info;
				this.$emit('allFormData',this.ruleForm)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.hf-main{
		padding: 39px 30px;
		font-family: '微软雅黑';
		.hf-top{
			display: flex;
			justify-content: flex-start;
			flex-wrap: wrap;
			text-align: left;
			line-height: 49px;
			width: 100%;
			.hf-top-item{
				width:33%; 
				display: flex;
				margin-top: 19px;
				.el-input{
					width: auto;
				}
				.item{
					width: 80px;
					.red{
						color: red;
					}
				}
				.relative{
					position: relative;
				}
				.absolute{
					position: absolute;
				}
				.tql-icon-t{
					border-left: 6px solid transparent;
					border-right: 6px solid transparent;
					border-bottom: 8px solid #ccc;
					cursor: pointer;
				}
				.tql-icon-b{
					border-left: 6px solid transparent;
					border-right: 6px solid transparent;
					border-top: 8px solid #ccc;
					cursor: pointer;
				}
				.tql-icon-t:hover{
					border-bottom: 8px solid #1C83ED;
				}
				.tql-icon-b:hover{
					border-top: 8px solid #1C83ED;
				}
				.topR{
					right: 10px;
					top:14px;
				}
				.bottomR{
					right: 10px;
					bottom: 14px;
				}
			}
			
		}
		.hf-center{
			display: flex;
			flex-wrap: wrap;
			line-height: 49px;
			.hf-center-item{
				width: 33%;
				display: flex;
				text-align: left;
				margin-top: 19px;
				.el-input{
					width: auto;
				}
				.cs-date {
					/deep/ .el-input__inner{
						padding: 0 5px
					}
					/deep/ .el-input__icon {
						display: none;
					}
					/deep/ .el-date-editor .el-range-input {
						width: 45%;
					}
					/deep/ .el-range-editor--small .el-range-input {
						font-size: 12px;
					}

				}
				.item{
					width: 80px;
					display: inline-block;
					.red{
						color: red;
					}
				}
				.setLJdate{
					width: 80px;
					.red{
						color: red;
					}
					
				}
				
				/deep/ .el-range-separator{
					padding: 0 5px;
					line-height: 47px;
					width: 20px;
					color: #303133;
				}	
				
				
			}
			
		}
		
		.hf-footer{
			display: flex;
			justify-content: flex-start;
			text-align: left;
			line-height: 49px;
			margin-top: 19px;
			.el-input{
				width: 100%;
			}
			/deep/ .el-input__inner{
				width: 93%;
			}
			.item{
				width: 85px;
				.red{
					color: red;
				}
			}
		}
		.costDialog{
			/deep/ .el-dialog{
				height: auto;
			}
			.cost-item{
				max-height: 500px;
				min-height: 330px;
				padding: 10px 20px;
				background-color: #dddddd;
				overflow-y: auto;
				.costName{
					font-size: 14px;
					line-height: 40px;
					margin-bottom: 3px;
					color: #000000;
					display: flex;
					justify-content: space-between;
					/deep/ .el-input__inner{
						height: 40px;
						width: 300px;
						line-height: 40px;
					}
				}
			}
			.costheader{
				background: rgba(0,0,0,0.6);
				height: 40px;
				width: 100%;
				padding: 7px 0 7px 20px;
					.cost-tab{
						width: 164px;
						height: 28px;
						line-height: 28px;
						border-radius: 28px;
						background-color: #cccccc;
						font-size: 14px;
						font-weight: 400;
						cursor: pointer;
						position: relative;
						overflow: hidden;
						text-align: center;
				
						.cost-ccr,
						.cost-sxr {
							display: inline-block;
							width: 82px;
							height: 28px;
							color: #000000;
							position: absolute;
						}
				
						.cost-ccr {
							left: 0;
							top: 0;
							z-index: 4;
						}
				
						.cost-sxr {
							left: 82px;
							top: 0;
							z-index: 3;
						}
				
						.cost-color {
							color: #00A7FA;
							transition: all 0.3s ease;
						}
					}
				
			}
			/deep/ .el-dialog__body {
				padding: 10px 20px 30px 20px;
			}
			/deep/ .el-dialog{
				width: 600px;
			}
		}
		.proDialog{
			/deep/ .el-dialog{
				width: 740px;
			}
			.el-input{
				width: 400px;
				height: 32px;
				line-height: 32px;
				width: auto;
			}
			/deep/ .el-input__inner{
				width: 400px;
				height: 34px;
				line-height: 34px;
				border-radius: 4px 0 0 4px;
			}
			/deep/ .el-dialog__body{
				padding: 20px;
			}
			.el-button{
				width: 60px;
				height: 32px;
				line-height: 30px;
				padding: 0;
				font-size: 14px;
			}
			
		}
		.textCenter{
			text-align: center;
		}

		/deep/ .el-card__body{
			padding: 0;
		}
		/deep/ .el-form-item__label{
			text-align: left;
			line-height: 49px;
		}
		/deep/ .el-form-item{
			margin-right: 0;
		}
		/deep/ .el-textarea__inner{
			height: 117px;
			background: #FFFFFF;
			border: 1px solid #DFE2E9;
		}
		
		.sxrButton{
			margin-bottom: 10px;
			/deep/.el-button{
				margin: 0 10px 10px 0;
			}
		}
	
		.flex{
			display: flex;
		}
		.between{
			justify-content: space-between;
		}
		.wrap{
			flex-wrap: wrap;
		}
		.first{
			justify-content: flex-start;
		}
			
		/deep/ .el-input__inner{
				width: 233px;
				height: 49px;
				border-radius: 4px;
				border: 1px solid #DFE2E9;
		}
		.textLeft{
			text-align: left;
		}
	}
</style>
