<template>
	<div class="a-main">
		<div class="content">
			<span class="text-name"><span class="red">* </span>审批规则</span>
			<el-input placeholder="请选择审批规则" :disabled='disabled' v-model="gzInfo.gzmc" readonly @click.native="showApproval"></el-input>
		</div>	
		<!-- 审批规则 -->
		<el-dialog title="审批规则"  :visible.sync="approvalVisible" class="approvalDialog" >
			<div>
				所选出行人涉及多条审批规则, 请选择审批规则后,再进行下一步操作
			</div>
			<el-table :data="approvalList" :header-cell-style="{ backgroundColor: 'rgba(64,160,255,0.1)'}" class="ta-table">
				<el-table-column  width="100">
					<template slot-scope="scope" >
						<el-radio v-model="gzInfo.gzid" :label="scope.row.gzid" @click.native="choosApproval(scope.row)"><span></span></el-radio>
					</template>
				</el-table-column>
				<el-table-column prop="gzmc" label="审批规则" width="200"></el-table-column>
				<el-table-column prop="spr" label="审批人" width="200"></el-table-column>
			</el-table>
			<div slot="footer" class="dialog-footer">
				<el-button  @click="cancel">取 消</el-button>
			    <el-button type="primary" @click="determine">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default{
		name: 'Approval',
		props:{
			// 出差人信息
			ccrInfo:{
				type: Object,
				default(){
					return {};
				}
			},
			// 出差单信息
			formInfo:{
				type: Object,
				default(){
					return {};
				}
			},
			// 传进来跟查询相关的字段
			queryPropObj: {
				type: Object,
				default(){
					return {
						// empId: '',
						// approvalId: '',
						// area: '',
						initQuery: true, // true-默认查询，false-默认不查询
					};
				}
			},
			initQuery: { // 初始化查询，true-查询，fakse-不查询，默认查询
				type: Boolean,
				default: true,
			},			
			// approvalId:{
			// 	type: String,
			// 	default: '',
			// },
   //          empId:{
   //              type: String,
			// 	default: '',
   //          }
			
		},
		data() {
			return {
				approvalVisible: false, //审批规则
				approvalList: [], // 审批规则列表
				currentInfo:{}, //单前审批规则
                disabled:false,
				appId:'',
                copyCcrInfo:{},
				gzInfo:{
					gzmc: '', //规则名称
					gzid: '', // 审批人信息
				}
				
			}
		},
		computed:{
			userInfo(){
				let userInfo = this.$common.sessionget('user_info');
				return userInfo;
			},
			empInfo(){
				return this.userInfo.empInfo;
			},
			vipCorp(){
				return this.userInfo.vipCorp;
			},
		},
        mounted(){
			this.$nextTick(() => {
				// 初始化查询
				if(this.initQuery) {
					this.queryApproval()
				}
			})
        },
		watch:{
			"ccrInfo.ccrId"(newVal) {
				this.copyCcrInfo.ccrId = newVal;
			},
			approvalPropObj(newObj) {
				this.appId = newObj.approvalId;
				this.queryApproval();
			},
			// approvalId(id){
			// 	if(id){
			// 		this.appId = id;
   //                  this.copyCcrInfo.ccrId=this.ccrInfo.ccrId
			// 		this.queryApproval()
			// 	}
			// },
   //          empId(id){
   //              if(id){
   //                  this.copyCcrInfo.ccrId=this.ccrInfo.ccrId
   //                  this.queryApproval()
   //              }
   //          },
   //          'formInfo.area':{
   //              handler(val){
   //                  this.queryApproval(val)
   //              }
   //          }
		},
		methods:{
			showApproval(){
		        // this.copyCcrInfo.ccrId=this.ccrInfo.ccrId
                if(this.approvalList.length==1 && this.copyCcrInfo.ccrId){
                    this.approvalVisible = false;
                }else if(this.approvalList.length!=1 && this.copyCcrInfo.ccrId) {
                    this.approvalVisible = true;
                }
				this.queryApproval();
			},
			// 查询审批规则
			queryApproval(val){
				if(!this.copyCcrInfo.ccrId){
					this.$message({
						type: 'error',
						message: '请选择出差人'
					})
					return	
				}
				let queryParams = {};
                console.log(this.formInfo,"this.formInfo")
				let days = this.$common.getDiffDate(this.formInfo.dateBegin, this.formInfo.dateEnd) + 1;
				queryParams = {
					bookEmpId: this.empInfo.empId,
					corpId:  this.empInfo.corpId,
					ifViolate: '',
					orderType: "11099",
					projectId: this.formInfo.projectId,
					travelEmpIds: this.copyCcrInfo.ccrId,
					days
				}
				if(val){
					queryParams.area=val
				}
				this.$conn.getConn('commu.singleModelMatchSpgz',{jsfile:false})(queryParams).then((res)=>{
					let data = res.data || [];
					this.approvalList = data;
					this.approvalList.forEach((item,index)=>{
                            if(this.approvalList.length==1){
                                this.gzInfo.gzid = item.gzid;
                                this.gzInfo.gzmc = item.gzmc;
                                this.disabled=true
                                this.$emit('approvalInfo',item);
                               
                            }else{
                                if(this.appId==item.gzid){
                                this.gzInfo.gzid = item.gzid;
                                this.gzInfo.gzmc = item.gzmc;
                                this.disabled=false
                                this.$emit('approvalInfo',item);
                            }
                        }

					})
				}).catch((err)=>{
					this.$message({
						type: 'error',
						message: err.tips
					})
				})					
			},
			// 选择审批规则
			choosApproval(row){
				this.currentInfo = row;
			},
			// 取消
			cancel(){
				this.approvalVisible = false;
			},
			// 确定
			determine(){
				if(!this.currentInfo.gzid){
					this.$message({
						type: 'warning',
						message: '您未选择审批规则'
					})
				}
				this.gzInfo = this.currentInfo;
				this.$emit('approvalInfo',this.gzInfo);
				this.approvalVisible = false;
			}
		}
	}
</script>

<style scoped lang="scss">
	.a-main{
		.content{
			margin-top: 19px;
			margin-bottom: 39px;
			display: flex;
			justify-content: flex-start;
			.el-input{
				width: auto;
				/deep/ .el-input__inner{
					width: 497px;
					height: 49px;
				}
			} 
		}
		.text-name{
			width: 80px;
			line-height: 49px;
			text-align: left;
		}
		.red{
			color: red;
		}
		.approvalDialog{
			/deep/ .el-dialog{
				width: 540px;
				text-align: left;
			}
			/deep/ .el-dialog__body{
				padding:10px 20px;
			}
		}
	}
</style>
