<template>
		<div class="train-list-loading">
			<el-skeleton
				style="width: 100%;"
				:loading="loading"
				animated
				:throttle="500"
				:count="1"
			>
				<template slot="template">
					<div class="filter-item">
						<el-skeleton-item 
							v-for="index in 2" 
							:key="index" 
							variant="button "
							style="width: 80px; height: 30px;margin-right: 15px; display: inline-block;"/>
						<div class="filter-item-right">
							<el-skeleton-item
								variant="button "
								style="width: 80px; height: 30px;margin-left: 15px; display: inline-block;"/>
						</div>	
					</div>
				</template>
			</el-skeleton>
			<el-skeleton
				style="width: 100%;"
				:loading="loading"
				animated
				:throttle="500"
				:count="1"
			>
				<template slot="template">
					<div style="display: flex;justify-content: space-between;padding: 0 20px;height: 60px;">
						<el-skeleton-item
								variant="image"
								style="width: 40px; height: 35px;"
								/>
						<el-skeleton-item
							v-for="index in 7" 
							:key="index" 
							variant="button "
							style="width: 80px; height: 35px;"/>
						<el-skeleton-item
								variant="image"
								style="width: 40px; height: 35px;"
								/>	
					</div>
				</template>
			</el-skeleton>
			<el-skeleton
				style="width: 100%"
				:loading="loading"
				animated
				:throttle="500"
				:count="1"
			>
				<template slot="template">
					<div style="display: flex;justify-content: space-between;padding: 0 20px;height: 60px;">
						<el-skeleton-item
							v-for="index in 9" 
							:key="index" 
							variant="button "
							style="width: 80px; height: 35px;"/>
						
					</div>
				</template>
			</el-skeleton>
			<el-skeleton
				style="width: 100%"
				:loading="loading"
				animated
				:throttle="500"
				:count="count"
				>
					<template slot="template">					
						<div class="flight-item">
							<!-- 日期信息 -->
							<div class="flight-item-info">
								<div class="flight-item-left">
									
									<el-skeleton-item
											variant="image"
											style="width: 40px; height: 35px;"
											/>
									<!-- <div class="flight-aircom-img"></div> -->
									<div class="flight-aircom-div">
										<el-skeleton-item
												variant="h3"
												style="width: 100px; height: 21px;display: block;"
												/>
										<el-skeleton-item
												variant="p"
												style="width: 100px; height: 14px;margin-top: 4px;"
												/>
										<!-- <div class="flight-aircom-name"></div>
										<div class="flight-aircom-flightNo"></div> -->
									</div>
								</div>
								<div class="flight-item-middle">
									<el-skeleton-item
											variant="h3"
											style="width: 400px; height: 21px;display: block;"
											/>
									<!-- <div class="flight-time"></div> -->
									<div class="flight-airport-name"></div>
								</div>
								<div class="flight-item-right">
									<el-skeleton-item
											variant="button "
											style="width: 80px; height: 30px;display: block;"
											/>
									<!-- <div class="book-btn"></div> -->
								</div>
							</div>
							<!-- 舱位信息 -->
							<div class="flight-item-cabin">
								<div class="flight-item-cabin-item" v-for="(cabin,cabinIndex) in cabinLength " :key="'cabin'+cabin">
									<!-- <div class="air-name"></div> -->
									<el-skeleton-item
											variant="p "
											style="width: 200px; height: 26px;"
											/>
									<!-- <div class="cabin-detail"></div> -->
									<el-skeleton-item
											variant="p "
											style="width: 300px; height: 26px;"
											/>
									<!-- <div class="book-btn"></div> -->
									<el-skeleton-item
											variant="button "
											style="width: 80px; height: 31px;"
											/>
									<div class="bottom-line" v-show="cabinIndex<cabinLength-1"></div>
								</div>	
								
							</div>
						</div>
					</template>
					
			</el-skeleton>
		</div>
</template>

<script>
	export default{
		name: "TrainListLoading",
		data(){
			return {
				rows:11, // 每一项有几个元素过渡
				count:6, // 共有多少项
				cabinLength:2,	
			}
		},
		props:{
			loading:{
				type:[Boolean,Function],
				default (){
					return false
				}
			}
		},
		
	}
</script>

<style scoped="scoped" lang="scss">

	.train-list-loading{
		background-color: white;
	}
	.filter-item{
		text-align: left;
		height: 60px;
		padding: 15px 20px;
		clear: both;
		.filter-item-left{
				float:left;
				text-align: left;
		}
		.filter-item-right{
				float:right;
				text-align: right;
		}
	}
	
	.flight-item{
		// 航班信息
		.flight-item-info{
			height: 80px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 20px;
			// 左侧信息
			.flight-item-left{
				width: 260px;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				.flight-aircom-img{
					width:40px ;
					height: 35px;
					background-color: #e4e4e4;
				}
				
				.flight-aircom-div{
					margin-left: 15px;
					// display: flex;
					// align-self: center;
					.flight-aircom-name{
						width:100px ;
						height: 21px;
						background-color: #e4e4e4;
					}
					.flight-aircom-flightNo{
						margin-top: 4px;
						width:100px ;
						height: 14px;
						background-color: #e4e4e4;
					}
				}
				
			}
			// 中间信息
			.flight-item-middle{
				width: 400px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;
				.flight-time{
					width: 400px;
					height: 21px;
					background-color: #e4e4e4;
				}
				.flight-airport-name{
					width:100% ;
					height: 17px;
					background-color: transparent;
				}
				
			}
			//右侧信息
			.flight-item-right{
				width: 430px;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				.book-btn{
					width: 57px;
					height: 31px;
					background-color: #e4e4e4;
				}
			}
		}
		// 舱位信息
		.flight-item-cabin{
			width: 100%;
			background-color: #f8fafb;
			padding:0 20px;
			.flight-item-cabin-item{
				height: 60px;
				width: 100%;
				
				// background-color: #e4e4e4;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding-left: 55px;
				position: relative;
				.air-name{
					width: 200px;
					height: 26px;
					background-color: #e4e4e4;
				}
				.cabin-detail{
					width: 300px;
					height: 26px;
					background-color: #e4e4e4;
				}
				.book-btn{
					width: 57px;
					height: 31px;
					background-color: #e4e4e4;
				}
				.bottom-line{
					height: 1px;
					width: 100%;
					background-color: #efefef;
					position: absolute;
					bottom:0;
					left: 0;
				}
				
			}
		}
	}
	
	
	
</style>

